import { Component } from '@angular/core';
import { HelpersService } from 'app/services/helpers.service';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'app/services/app-config.service';

@Component({
    selector   : 'docs-changelog',
    templateUrl: './changelog.component.html',
    styleUrls  : ['./changelog.component.scss']
})
export class DocsChangelogComponent
{
    constructor(
        private http: HttpClient,
        public helpers: HelpersService)
    {
        this.loadChangelog();
    }
    changeLogContent: string;
    loadingChangelog: boolean;


    loadChangelog() {
        this.loadingChangelog = true;
        this.http.get(AppConfig.settings.apiurl + '/systemparameters/changelog', {
          responseType: 'text'
        })
          .subscribe((r: string) => {
            this.changeLogContent = r;
            this.loadingChangelog = false;
          }, e => {
            this.helpers.HandleNonSuccessfulHttpResponse(e);
            this.loadingChangelog = false;
          });
      }

}
