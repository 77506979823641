import { Component, ViewEncapsulation } from '@angular/core';

export interface QuickPanelElement{
    title: string;
    detail: string;
}

@Component({
    selector     : 'quick-panel',
    templateUrl  : './quick-panel.component.html',
    styleUrls    : ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuickPanelComponent
{
    date: Date;
    events: any[];
    notes: QuickPanelElement[]=[];
    settings: any;

    /**
     * Constructor
     */
    constructor()
    {
        // Set the defaults
        this.date = new Date();
        this.settings = {
            notify: true,
            cloud : false,
            retro : true
        };
        this.notes.push({title:"Dispositivos", detail:"Ahora podes manejar los dispositivos desde"});
        this.notes.push({title:"Licencias", detail:"Ahora podes consultar las licencias del RENALIC"});
    }
}
