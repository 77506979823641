import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'relacionFamiliar'
})
export class RelacionFamiliar implements PipeTransform {

  transform(value: any, relaciones: any, prop: string = 'id'): any {
    
    if(value !== 0){
      
      const relacion = relaciones.find(relacion => relacion.id === value);
      for (const key in relacion) {

        if (relacion.hasOwnProperty(key) && key === prop) {
          
          return relacion[key];          
        }
      }
    }  

    return null;    
  }


}
