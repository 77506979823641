import { FuseNavigation, FuseNavigationItem } from "@fuse/types";

export const navigation: FuseNavigation[] = [
  {
    id: "menues",
    title: "Menues",
    // translate: 'NAV.APPLICATIONS',
    type: "group",
    children: [
      // {
      //     id       : 'sample',
      //     title    : 'Sample',
      //     // translate: 'NAV.SAMPLE.TITLE',
      //     type     : 'item',
      //     icon     : 'email',
      //     url      : '/sample'
      // },
      {
        id: "administracion",
        title: "Administración",
        type: "collapsable",
        icon: "donut_large",
        // url      : '/retencion',
        children: [
          {
            id: "users",
            title: "Usuarios",
            type: "item",
            icon: "supervisor_account",
            url: "/users"
          },
          {
            id: "roles",
            title: "Roles",
            type: "item",
            icon: "supervised_user_circle",
            url: "/roles"
          },
          {
            id: "menuad",
            title: "Menues",
            type: "item",
            icon: "menu",
            url: "/menus"
          }
        ]
      },
      {
        id: "consultaretencion",
        title: "Consulta retención",
        type: "item",
        icon: "search",
        url: "/pages/consulta"
      },
      {
        id: "devolucion",
        title: "Devolución",
        type: "item",
        icon: "vertical_align_top",
        url: "/pages/devolucion"
      },
      {
        id: "retencion",
        title: "Retención",
        type: "item",
        icon: "vertical_align_bottom",
        url: "/pages/retencion"
      }
    ]
  }
];
/**
 * Menu basado en la data recibida del server Fiscalizar
 */
export class FiscalizarNavigationTree {

  constructor(public data) {}
  // navigation;
  build(): FuseNavigation[] {
    const tree:FuseNavigationItem[]=[];
    this.data.forEach(element => {
        const branch = this.createBranch(element);
        tree.push(branch);
      });

    const fuseMenu: FuseNavigation[] = [{
        id: "menues",
        title: "Menues",
        // translate: 'NAV.APPLICATIONS',
        type: "group",
        children: tree,
    }];
    return fuseMenu;
  }

  private getChildren(parent): FuseNavigationItem[] {
    const children: FuseNavigationItem[] = [];
    parent.children.forEach(child => {
      const item: FuseNavigationItem = {
        id: child.menuID,
        title: child.name,
        type: "item",
        icon: child.icon,
        url: "./pages/" + child.url
      };

      children.push(item);
    });

    return children;
  }

  private createBranch(item): FuseNavigationItem {
    let branch: FuseNavigationItem;
    // Hijos del item
    if (item.children && item.children.length > 0) {
      branch = {
        id: item.menuID,
        title: item.name,
        type: "collapsable",
        children: this.getChildren(item)
      };
    } else {
      // No tiene hijos, es una hoja
      branch = {
        id: item.menuID,
        title: item.name,
        type: "item",
        icon: item.icon,
        url: "./pages/" + item.url,
        function:()=>{
          for (var i = 0; i < localStorage.length; i++){
            if(localStorage.key(i).startsWith('filters_'))
              localStorage.removeItem(localStorage.key(i));
      }}
      };
    }

    return branch;
  }
}
