import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  bread: Bread = {
    text: 'Home',
    url: '/pages/home'
  }

  // List wich will contains url's data
  items: Bread[] = [
    this.bread
  ];

  constructor() { }
  // clean the list and add "home" as a first element
  init() {
    this.items = [];
    this.items.push({
      text: 'Home',
      url: '/'
    });
  }

  urlAnterior;
  urlActual;
  // pushes the parameters to the new list
  // this will be new in each component, you must push an item for each path in the url you want to add
  // Example:
  // we are now in the path "pages/stores/books/mistborn-el-heroe-de-las-eras"
  // @ngOnInit(){
  //#region Breadcrumb
  //  this.init();
  // this.push('Stores', '/pages/stores');
  // this.push('Books Galerie', '/pages/stores/books');
  // this.push('Mistborn Saga', '/pages/stores/books/mistborn-el-heroe-de-las-eras');
  // }
  //#endregion Bredcrumb
  push(text: string, url: string) {
    const b = new Bread();
    b.text = text;
    b.url =  url;

    const checkItems = this.items.filter(x => x.url == b.url);

    if(checkItems.length > 0){
      const indexOfCheckedItems = this.items.indexOf(checkItems[0]);
      this.items = this.items.splice(0, indexOfCheckedItems + 1);
    } else {
      this.items.push(b);
    }
  }

  clean(){
    this.items = [
      this.bread
    ];
  }
  // replaceLast(text: string, url: string) {
  //   this.items.pop();
  //   this.push(text, url);
  // }
}
export class Bread {
  text: string;
  url: string
}