import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class NotifyService {
  constructor(public snackBarService: MatSnackBar) {
   }

  // danger, warning, info, success
  public notify(text: string, type?: string): void {
    if (type !== 'danger' && type !== 'warning' && type !== 'success') {
      type = 'info';
    }
    let icon = '';
    let color = '';
    switch (type) {
      case 'danger': {
        icon += 'error';
        color = '#FF3131';
        break; }
      case 'warning': {
        icon += 'warning';         
        color = '#FFC33B';  
        break; }

      case 'success': {
        icon += 'done';         
        color = '#0BB200';
        break; }

      case 'info': {
        icon += 'info';         
        color = '#32BEFF';
        break; }
    }
    // TODO: Usar template ref.
    const htmlnoti = `<i class="material-icons" style="color: ${color}">${icon}</i> <p>${text}</p>`;

    this.snackBarService.open(text, 'Cerrar' , {duration: 7000});
  }
}
