import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxValue'
})
export class FechaMaxValue implements PipeTransform {

  transform(value: any, args: any): any {
    if(value === 	'31/12/9999') {
      return '';
    } else {
      return value;
    }
  }

}
