import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'edad'
})
export class Edad implements PipeTransform {

  transform(value: any, args: any): any {
    if(typeof value.getMonth === 'function'){
      let a = new Date().getTime();
      let b = value.getTime();
      let c = a - b;
      return Math.floor(c/ 1000 / 3600 / 24 / 365);
    } else {
      const d = new Date(value);
      if(typeof d.getMonth === 'function'
      ){
        let a = new Date().getTime();
        let b = d.getTime();
        let c = a - b;
        return Math.floor(c/ 1000 / 3600 / 24 / 365);
      } else {
        return value;
      }
    }
  }


}
