import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'app/services/breadcrumb.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

 // this TS component just make the service public to may use it in the HTML component
 constructor(
  public bcs: BreadcrumbService
) { }

  ngOnInit() {
  }

}
