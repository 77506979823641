import { Injectable } from '@angular/core';
import { NotifyService } from './notify.service';
import { HttpParams } from '@angular/common/http';


@Injectable({
  providedIn: 'root',
})
export class HelpersService {

  //Variable para validar si el usuario logueado funcionará como solo lectura
  //Se establece en este servicio porque no funcionan bien las especificaciones de roles
  //Queda para implementar Nebular y tomar esta info desde el rol del usuario
  userIsReadOnly: boolean = false;
  constructor(
    public notification: NotifyService,
  ) {
    //Valida si está Logueado si es un usuario de solo lectura
    const authDataJson = localStorage.getItem('authData');
    if (authDataJson) {
      const authData = JSON.parse(authDataJson);
      if (authData) {
        //Valida si el usuario es de solo lectura CAMBIAR debería ser con el ROL pero el AUTH soporta 1 solo
        if (authData.user.externalID.includes('readOnly'))
          this.setUserReadOnly(true);
      }
    }
  }

  /**
   * Handles a non successful http response. Notifications will be raised acording the status code
   * @param httpResponse - Instance of HttpResponseMessage
   * @param codeLocation - (Optional) String indicating the location of the error in the code
   * @param forbidenMessageAppend - (Optional) Message
   *  to append after 'No tiene el permiso necesario para
   *  ejeutar la acción deseada. Si considera que esto es un error contáctese con administración. '
   * @param unexpectedErrorMessageAppend - (Optional)
   * Message to append after 'Surgió un error inesperado.
   *  Intente nuevamente. Si el problema persiste comuníquese con soporte. '
   * @version 1.0
   */
  public HandleNonSuccessfulHttpResponse(
    httpResponse: any,
    codeLocation?: string,
    forbidenMessageAppend?: string,
    unexpectedErrorMessageAppend?: string): void {

    if (codeLocation && codeLocation.length > 0) {
      codeLocation = '[' + codeLocation + '] ';
    }

    if (httpResponse.status === 400) {
      // Handle badrequest
      console.warn(codeLocation + 'Error de negocio: ' + httpResponse.error, httpResponse);
      this.notification.notify('Error de negocio: ' + httpResponse.error);
    } else if (httpResponse.status === 401) {
      // Handle unauthorized
      console.warn(codeLocation + 'Error de autenticación', httpResponse);
      // tslint:disable-next-line:max-line-length
      this.notification.notify('No se ha podido determinar su identidad. Por favor, vuelva a iniciar sesión o comuníquese con soporte.');
    } else if (httpResponse.status === 403) {
      // Handle forbiden
      console.warn(codeLocation + 'Permiso faltante: ' + httpResponse.error, httpResponse);
      // tslint:disable-next-line:max-line-length
      this.notification.notify('No tiene el permiso necesario para ejeutar la acción deseada. Si considera que esto es un error contáctese con administración. ' + (forbidenMessageAppend ? forbidenMessageAppend : ''));
    } else if (httpResponse.status === 404) {
      // Handle resource not found
      console.warn(codeLocation + 'Recurso no encontrado', httpResponse);
      // tslint:disable-next-line:max-line-length
      this.notification.notify('El recurso al que está intentando acceder no existe o fue eliminado.');
    } else if (httpResponse.status === 0) {
      // Handle server unreachable
      console.warn(codeLocation + 'Sin conexión', httpResponse);
      // tslint:disable-next-line:max-line-length
      this.notification.notify('No se pudo acceder al servidor. Verifique su conexión a internet o comuníquese con soporte.');
    } else {
      // Handle unknown error
      console.error(codeLocation + 'Error inesperado', httpResponse);
      // tslint:disable-next-line:max-line-length
      this.notification.notify('Surgió un error inesperado. Intente nuevamente. Si el problema persiste comuníquese con soporte. ' + (unexpectedErrorMessageAppend ? unexpectedErrorMessageAppend : ''));
    }
  }
  public createHttpParams(p: {}): HttpParams {
    if (p == null || p == undefined)
      return null;
    let httpParams: HttpParams = new HttpParams();
    Object.keys(p).forEach(param => {
      if (p[param]) {
        httpParams = httpParams.set(param, p[param]);
      }
    });

    return httpParams;
  }


  //#region guarda los filtros de busqueda en localStorage
  setObjectToStorage(key: string, obj: any) {
    try {
      localStorage.setItem(key, JSON.stringify(obj));
      return true;
    } catch {
      return false;
    }
  }
  //#endregion
  //#region obtiene los filtros de busqueda de localStorage
  getObjectFromStorage(key: string): any {

    const defaultConfig = {
      pageSize: 10,
      sortValue: "asc",
      //sortKey: defaultSortKey,
      pageIndex: 1,
      total: 0,
      searching: false,
      //fromDate:{year: desde.getFullYear(), month:desde.getMonth()+1, day:desde.getDate()},
      //toDate:{year: ahora.getFullYear(), month:ahora.getMonth()+1, day:ahora.getDate()},
    };

    const objStr = localStorage.getItem(key);
    if (!objStr)
      return defaultConfig;
    try {
      const obj = JSON.parse(objStr);
      return obj;
    } catch {
      return defaultConfig;
    }
  }
  removeMenuFilters() {
    for (var i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).startsWith('filters_'))
        localStorage.removeItem(localStorage.key(i));
    }
  }
  //#endregion

  //Establece un mecanismo para poder verificar si el usuario es ReadOnly lo saca de los roles o claims del usuario
  setUserReadOnly(value: boolean) {
    this.userIsReadOnly = value;
  }

  public getUrlName(url: string) {
    let stringsplit = url.split("/");
    // Por las dudas, para evitar parametros de query.
    return stringsplit[2].split('?')[0];
  }

  fecha() {
    let d = new Date();
    let r = d.getFullYear() + '-' + this.pad(d.getMonth() + 1) + '-' + this.pad(d.getDate());
    return r;
  }
  fechaDias(dias) {
    let d = new Date();
    d.setDate(d.getDate() + dias);
    let r = d.getFullYear() + '-' + this.pad(d.getMonth() + 1) + '-' + this.pad(d.getDate());
    return r;
  }
  pad(number) {
    if (number < 10) {
      return '0' + number;
    }
    return number;
  }
}
