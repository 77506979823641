import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FuseSearchBarModule } from '@fuse/components';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexLayoutModule } from '@angular/flex-layout';

import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { FuseConfirmDialogModule } from '@fuse/components/confirm-dialog/confirm-dialog.module';
import { RelacionFamiliar } from 'app/pipes/relacionFamiliar.pipe';
import { Edad } from 'app/pipes/edad';
import { FechaMinValue } from 'app/pipes/fechaMinValue';
import { FechaMaxValue } from 'app/pipes/fechaMaxValue';
import { BreadcrumbComponent } from 'app/main/pages/breadcrumb/breadcrumb.component';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';

@NgModule({
    declarations: [
        RelacionFamiliar,
        Edad,
        FechaMinValue,
        FechaMaxValue,
        BreadcrumbComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        FuseDirectivesModule,
        FusePipesModule,

        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        FuseSearchBarModule,
        MatSelectModule,
        MatTooltipModule,
        NgxMaterialTimepickerModule,
        MatDatepickerModule,
        MatCardModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        MatTableModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatSortModule,
        MatDividerModule,
        MatDialogModule,
        FuseConfirmDialogModule,
        RouterModule
    ],
    exports: [
        FechaMaxValue,
        RelacionFamiliar,
        FechaMinValue,
        Edad,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        FuseDirectivesModule,
        FusePipesModule,

        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        FuseSearchBarModule,
        MatSelectModule,
        MatTooltipModule,
        NgxMaterialTimepickerModule,
        MatDatepickerModule,
        MatCardModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        MatTableModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatSortModule,
        MatDividerModule,
        MatDialogModule,
        MatGridListModule,

        BreadcrumbComponent
    ]
})
export class FuseSharedModule {
}
