import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { FuseNavigation } from "@fuse/types";
import { AuthService } from "angular-6-social-login";
import { OAuthService } from "angular-oauth2-oidc";
import { FiscalizarNavigationTree } from "app/navigation/navigation";
import * as jwt_decode from "jwt-decode";
import { AuthResponse } from "../clases";
import { AppConfig } from "../services/app-config.service";
import { NotifyService } from "../services/notify.service";
import { HelpersService } from './helpers.service';

@Injectable({
  providedIn: "root"
})
export class LoginService {
  public IsLogged = false;
  public AuthData: any;

  constructor(
    private socialAuthService: AuthService,
    private oAuthService: OAuthService,
    private http: HttpClient,
    private router: Router,
    private notifyService: NotifyService,
    public helpers:HelpersService
  ) {
  }

  // Llama al backend, el cual valida el accessToken y devuelve el usuario con los permisos y los guarda en localStorage
  // tslint:disable-next-line:typedef
  Login(
    externalId: string,
    // tslint:disable-next-line:variable-name
    access_token?: string,
    authProvider?: string,
    email?: string,
    fullname?: string,
    identifier?: string,
    password?: string,
    callback?
  ) {

    this.http
      .post(AppConfig.settings.apiurl + "/auth", {
        ExternalId: externalId,
        UserAuthToken: access_token,
        AuthorityProvider: authProvider,
        Identifier: identifier,
        Password: password
      })
      .subscribe(
        authResp => this.HandleLoginOKResponse(authResp),
        (e: HttpErrorResponse) => {
          // ERROR
          if (e.status === 400 && e.error.contains("unknown_user")) {
            if (e.error.contains("can_register")) {
              this.notifyService.notify(
                "El usuario no existe, complete el formulario para solicitar el alta",
                "warning"
              );
              this.router.navigate(["/auth/register"], {
                queryParams: {
                  email: email,
                  fullname: fullname,
                  externalid: externalId,
                  userAuthToken: access_token,
                  authorityProvider: authProvider
                }
              });
            } else {
              this.notifyService.notify("El usuario no existe", "warning");
            }
            return;
          } else if (e.status === 400) {
            console.log(
              "LoginService.ValidateAndGetUser BUSINESS ERROR: " + e.message
            );
            this.notifyService.notify(e.error, "warning");
          } else {
            console.log("LoginService.ValidateAndGetUser ERROR", e);
          }
          callback();
        }
      );
  }
  // tslint:disable-next-line:typedef
  HandleLoginOKResponse(authResp) {
    localStorage.setItem("authData", JSON.stringify(authResp));
    //Valida si el usuario es de solo lectura CAMBIAR debería ser con el ROL pero el AUTH soporta 1 solo
    if(authResp.user.externalID.includes('readOnly'))
          this.helpers.setUserReadOnly(true);
    this.router.navigate(["/"]);
  }

  // tslint:disable-next-line:typedef
  SignOut() {
    // Obtener provider actual
    const authDataJson = localStorage.getItem("authData");
    const authData = JSON.parse(authDataJson) as AuthResponse;
    // Deslogueo según el proveedor
    if (authData.AuthProvider === "miargentina") {
      this.oAuthService.logOut();
    } else if (authData.AuthProvider === "google") {
      this.socialAuthService.signOut();
    } else if (
      authData.AuthProvider === "ad" ||
      authData.AuthProvider === "local"
    ) {
      // para desloguear ad o local basta con borrar 'authData' de ls
    } else {
      console.log(
        "Proveedor de autenticación desconocido: " + authData.AuthProvider
      );
    }
    // Borro data del usuario
    localStorage.clear();
    this.router.navigate(["/auth/login"]);
  }

  // tslint:disable-next-line:typedef
  getUserLoggedData() {
    let authenticated = false;
    const authDataJson = localStorage.getItem("authData");
    const authData = JSON.parse(authDataJson);
    if (authData != null) {
      switch (authData.authProvider) {
        case "miargentina":
          authenticated = this.oAuthService.hasValidAccessToken();
          break;
        case "google":
          authenticated = this.socialAuthService.authState != null;
          break;
        case "ad":
          authenticated = true;
          break;
        case "local":
          authenticated = true;
          break;
        default:
          console.log(
            "Proveedor de autenticación '" +
              authData.authProvider +
              "' desconocido"
          );
      }
    } else {
      console.log("No está autenticado con el servidor");
    }

    // chk jwt exp
    if (authData) {
      const payload = jwt_decode(authData.jwtToken);
      const jwtExp = new Date(payload.expiration);
      // Se considera vencido media hora antes
      const prontaExpiracion = new Date(jwtExp.getTime() - 30 * 60000);
      if (prontaExpiracion < new Date()) {
        console.log(
          "El jwt está por vencer. jwtExp:" +
            jwtExp +
            ". prontaExpiracion:" +
            prontaExpiracion
        );
        authenticated = false;
      }
    }

    if (!authenticated) {
      this.router.navigate(["/auth/login"]);
      return null;
    }
    this.IsLogged = authData != null;
    this.AuthData = authData;
    // Guardar rutas apis en localStorage
    localStorage.setItem("enabledApiRoutes", authData.enabledApiRoutes);

    return this.IsLogged;
  }

  isUserLoggedIn() {
    return localStorage.getItem("authData");
  }

  //private getMenuParent(parent):

  getMenu(): FuseNavigation[] {
    const userData = JSON.parse(localStorage.getItem("authData"));
    if (!userData) {
      console.log(
        "No se puede acceder a la información del usuario para construir el menu"
      );
      return [];
    }

    const menu = new FiscalizarNavigationTree(userData.enabledMenus);
    return menu.build();
  }
}
