import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { AuthGuard } from './services/auth-guard.service';
import {
  SocialLoginModule,
  AuthServiceConfig,
  GoogleLoginProvider
} from 'angular-6-social-login';
import { AppConfig } from './services/app-config.service';
import { Globals, getSpanishPaginatorIntl } from './services/global';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { TokenInterceptor } from './services/http-interceptor.service';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthModule } from './main/auth/auth.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE, OwlDateTimeIntl } from 'ng-pick-datetime';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { DocsChangelogComponent } from './main/changelog/changelog.component';
import {  MatDatepickerModule } from '@angular/material/datepicker';
import { MarkdownModule } from 'ngx-markdown';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateModule } from '@angular/material/core';
//PARA USAR ARGENTINA
// import { registerLocaleData } from '@angular/common';
// import localeEs from '@angular/common/locales/es-AR';
// registerLocaleData(localeEs, 'es-AR');

// Internacionalizacion del datetimepicker.
export class CustomIntl extends OwlDateTimeIntl {
  upSecondLabel = 'Agregar un segundo';
  downSecondLabel = 'Restar un segundo';
  upMinuteLabel = 'Agregar un minuto';
  downMinuteLabel = 'Restar un minuto';
  upHourLabel = 'Agregar una hora';
  downHourLabel = 'Restar una hora';
  prevMonthLabel = 'Mes anterior';
  nextMonthLabel = 'Proximo mes';
  prevYearLabel = 'Año anterior';
  nextYearLabel = 'Proximo año';
  prevMultiYearLabel = '21 años anteriores';
  nextMultiYearLabel = 'Proximos 21 años';
  switchToMonthViewLabel = 'Cambiar a vista por mes';
  switchToMultiYearViewLabel = 'Elija mes y año';
  cancelBtnLabel = 'Cancelar';
  setBtnLabel = 'Aceptar';
  rangeFromLabel = 'Desde';
  rangeToLabel = 'Hasta';
  hour12AMLabel = 'AM';
  hour12PMLabel = 'PM';
}

const appRoutes: Routes = [
  {
    path: 'pages',
    // loadChildren: './main/pages/pages.module#PagesModule',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    // loadChildren: './main/auth/auth.module#AuthModule',
    loadChildren: () => import('./main/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'changelog',
    component: DocsChangelogComponent
  },
  {
    path: '**', pathMatch: 'full', redirectTo: 'pages/home'
  },
  {
    path: '', redirectTo: 'pages/home', pathMatch: 'full'
  }
];
registerLocaleData(es);

// Configs
export function getAuthServiceConfigs(): any {
  const providers: Array<any> = [];

  if (AppConfig.settings.auth.google.active) {
    providers.push({
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider(AppConfig.settings.auth.google.clientid)
    });
  }

  const config = new AuthServiceConfig(providers);
  return config;
}
export function initializeApp(appConfig: AppConfig): any {
  return () => appConfig.load();
}

@NgModule({
  declarations: [
    AppComponent,
    DocsChangelogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    MarkdownModule.forRoot(),
    TranslateModule.forRoot(),

    MatDatepickerModule,
    NativeDateModule,
    // Material
    MatButtonModule,
    MatIconModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    // App modules
    LayoutModule,
    AuthModule,
    SocialLoginModule,
    OAuthModule.forRoot(),
    MatSnackBarModule,
    NgxMaterialTimepickerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: getSpanishPaginatorIntl() },
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig], multi: true
    },
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs
    },
    Globals,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    // {provide: OWL_DATE_TIME_LOCALE, useValue: 'es-AR'},
    // {provide: OwlDateTimeIntl, useClass: CustomIntl},
    // {provide: MAT_DATE_LOCALE, useValue: 'es-AR'},
    // {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
    {
      provide: LOCALE_ID,
      useValue: 'es'
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'es'
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'D/MM/YYYY'
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMMM Y',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM Y'
        }
      }
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter
    }
  ],
})
export class AppModule {

}
