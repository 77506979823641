import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OAuthService } from 'angular-oauth2-oidc';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService, GoogleLoginProvider } from 'angular-6-social-login';
import { LoginService } from 'app/services/login.service';
import { Router } from '@angular/router';
import { AppConfig } from 'app/services/app-config.service';
import { NotifyService } from 'app/services/notify.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { HelpersService } from 'app/services/helpers.service';

@Component({
  selector: 'login-2',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    googleIsEnabled: boolean;
    miargentinaIsEnabled: boolean;
    working: boolean;
  
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
  constructor(private _fuseConfigService: FuseConfigService,
              private _formBuilder: FormBuilder,
              private oauthService: OAuthService,
              private http: HttpClient,
              private socialAuthService: AuthService,
              private loginService: LoginService,
              private router: Router,
              private notifyService: NotifyService,
              private _fuseNavigationService: FuseNavigationService,
              public helpers: HelpersService
) {
  this.googleIsEnabled = AppConfig.settings.auth.google.active;
  this.miargentinaIsEnabled = AppConfig.settings.auth.miargentina.active;

          // Configure the layout
  this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
 }

  ngOnInit(): void {
    this.loginForm = this._formBuilder.group({
      email   : ['', [Validators.required]],
      password: ['', Validators.required]
  });

  }
  miArgentinaSignIn(): any {
    this.oauthService.initImplicitFlow();
  }
  
  // tslint:disable-next-line:typedef
  socialSignIn(socialPlatform: string) {
    let socialPlatformProvider;
    if (socialPlatform === 'google') {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }
    this.socialAuthService.signIn(socialPlatformProvider).then(
      (userData) => {
        this.loginService.Login(userData.id, userData.token, 'google', userData.email, userData.name);
      }
    );
  }
  // tslint:disable-next-line:typedef
  localSignIn() {
    this.working = true;
    // this.loginService.Login(null, null, 'local', null, null, this.userIdentifier, this.userPassword, this.callback);
    localStorage.clear();

    this.http.post(
      AppConfig.settings.apiurl + '/auth',
      {
        AuthorityProvider: 'local',
        Identifier: this.loginForm.controls['email'].value,
        Password: this.loginForm.controls['password'].value
    })
    .subscribe(
      (authResp) => this.HandleLoginOKResponse(authResp),
      (e: HttpErrorResponse) => {
        this.working = false;
        if (e.status === 400 && e.error === 'unknown_user') {
          this.notifyService.notify('El usuario no existe', 'warning');
        } else if (e.status === 400) {
          this.notifyService.notify(e.error, 'warning');
        } else {
          this.notifyService.notify('Surgió un error inesperado', 'danger');
        }
      });
  }
  // tslint:disable-next-line:typedef
  HandleLoginOKResponse(authResp) {
    this.working = false;
    this.notifyService.notify('Bienvenido ' + authResp.user.fullName + '!');
    localStorage.setItem('authData', JSON.stringify(authResp));

    //Valida si el usuario es de solo lectura CAMBIAR debería ser con el ROL pero el AUTH soporta 1 solo
    if(authResp.user.externalID.includes('readOnly'))
      this.helpers.setUserReadOnly(true);
    else 
      this.helpers.setUserReadOnly(false);
    if(this._fuseNavigationService.getNavigation("menu-fiscalizar")){
        this._fuseNavigationService.unregister("menu-fiscalizar")
    }
    //Configuracion del menu
    this._fuseNavigationService.register("menu-fiscalizar", this.loginService.getMenu());

    // Set the main navigation as our current navigation
    this._fuseNavigationService.setCurrentNavigation("menu-fiscalizar");

    this.router.navigate(['/']);
  }
}
